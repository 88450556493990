import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import firebase from "firebase/app";
import "firebase/auth";
import { CssBaseline } from "@material-ui/core";

const firebaseConfig = {
  apiKey: "AIzaSyA_DnxEOvBcApawLcv0e_j4LlQo8BLj4dE",
  authDomain: "daedalus-investment-fund.firebaseapp.com",
  projectId: "daedalus-investment-fund",
  storageBucket: "daedalus-investment-fund.appspot.com",
  messagingSenderId: "228677136733",
  appId: "1:228677136733:web:d4d5e9f0870355f73038b4",
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
