import _ from "lodash";
import { DateTime } from "luxon";

export const fetcher = (...args) => fetch(...args).then((res) => res.json());

export const authenticatedTaxRequest = async (url, user, payload) => {
  const token = await user.getIdToken(true);

  const res = await fetch(url, {
    method: "post",
    headers: {
      Authorization: token,
      // "Content-Type": "application/pdf",
    },
    body: JSON.stringify(payload),
  });

  return res.json();
};

export const authenticatedPostRequest = async (url, user, payload) => {
  const token = await user.getIdToken(true);

  const res = await fetch(url, {
    method: "post",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const resData = await res.json();
  return resData;
};

export const authenticatedFetcher = (url, user) => {
  return user
    .getIdToken(true)
    .then((token) =>
      fetch(url, { headers: { Authorization: token } }).then((res) =>
        res.json()
      )
    );
};

export const toPercent = (decimal, fixed) => {
  return (decimal * 100).toFixed(fixed) + "%";
};

export const getPerformanceByMonth = (data = []) => {
  if (data.length === 0) {
    return [];
  }

  const sorted = _.sortBy(data, (d) => d.date);
  const grouped = _.groupBy(sorted, (d) => d.name);
  const keys = Object.keys(grouped);

  let chartData = [];

  for (let i = 0; i < keys.length; i++) {
    const underlyingSymbol = keys[i];
    const underlyingRows = grouped[underlyingSymbol];

    let mtdRow = {};

    for (let k = 0; k < underlyingRows.length; k++) {
      const row = underlyingRows[k];

      const rowMonth = DateTime.fromFormat(row.date, "yyyy-MM-dd").toFormat(
        "yyyy-MM"
      );

      // if no month exists, set start
      if (!mtdRow.month) {
        mtdRow = {
          month: rowMonth,
          start: row.price,
        };
      } else if (rowMonth !== mtdRow.month) {
        // if row month (this current row) is not equal to mtd row month (our buffer row)
        // we calculate performance and move to next month
        const { end } = mtdRow;
        const geometric = end / mtdRow.start;

        // find the set in chart data and append
        const index = chartData.findIndex((d) => {
          return d.date === mtdRow.month;
        });

        if (index >= 0) {
          chartData[index] = {
            ...chartData[index],
            date: mtdRow.month,
            [underlyingSymbol]: geometric - 1,
          };
        } else {
          chartData.push({
            date: mtdRow.month,
            [underlyingSymbol]: geometric - 1,
          });
        }

        // todo this month finished, but its getting included.
        // todo must ensure that we cashflow only on the first of the month
        mtdRow = { month: rowMonth, start: end + row.cashflow, end: row.price };
      } else {
        mtdRow.end = row.price;
      }
    }

    const { end } = mtdRow;
    const geometric = end / mtdRow.start;

    // do a manual push since the month wont change on the last entry
    const index = chartData.findIndex((d) => d.date === mtdRow.month);

    if (index >= 0) {
      chartData[index] = {
        date: mtdRow.month,
        ...chartData[index],
        [underlyingSymbol]: geometric - 1,
      };
    } else {
      chartData.push({
        date: mtdRow.month,
        [underlyingSymbol]: geometric - 1,
      });
    }
  }

  return chartData;
};
