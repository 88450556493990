import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuth } from "./authentication/firebase";
import AuthenticatedApp from "./AuthenticatedApp";
import React from "react";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  callbacks: {
    signInSuccessWithAuthResult: function () {
      return false;
    },
  },
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

function App() {
  const { user, authLoading } = useAuth();

  return (
    <div>
      {user && <AuthenticatedApp user={user} />}
      {!user && !authLoading && (
        <StyledFirebaseAuth
          uiCallback={(ui) => ui.disableAutoSignIn()}
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )}
    </div>
  );
}

export default App;
