import React, { useState } from "react";
import useSWR from "swr";
import { signOut, useAuth } from "./authentication/firebase";

import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MyAccount from "./components/MyAccount";
import {
  authenticatedFetcher,
  authenticatedPostRequest,
  fetcher,
} from "./util";
import _ from "lodash";
import { DateTime } from "luxon";
import FundTrrYtdCard from "./components/FundTrrYtdCard";
import FundTrrItdCard from "./components/FundItdCard";
import FundTrrMonthlyYtdCard from "./components/FundTrrMonthlyYtdCard";
import { ModifyInvestmentDialog } from "./components/ModifyInvestmentDialog";
import TaxVerificationDialog from "./components/TaxVerificationDialog";
import { HOSTNAME } from "./constants";

// todo fix check for email unverified
const PerformanceCard = ({ user, accountAndProfileData }) => {
  const { data: daedalusYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/daedalus/daily/ytd`, user],
    authenticatedFetcher
  );

  const { accountData } = accountAndProfileData;

  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  // const sortedPerformanceData = _.sortBy(performanceData, (d) => d.datetime);
  const lastUpdatedStr = _.last(daedalusYtd)?.datetime;
  let lastUpdatedLocalized = "";

  if (lastUpdatedStr) {
    lastUpdatedLocalized = DateTime.fromISO(lastUpdatedStr, { zone: "UTC" })
      .toLocal()
      .toFormat("MM/dd/yyyy HH:mm");
  }

  return (
    <Card style={{ marginBottom: 8 }}>
      <CardHeader
        title={
          <>
            <Typography variant="h6">My Account</Typography>
            {daedalusYtd && (
              <Typography variant="body2">
                Last Updated: {lastUpdatedLocalized}
              </Typography>
            )}
          </>
        }
        style={{ textAlign: "center" }}
      />

      <CardContent>
        {accountAndProfileData?.reason === "email_unverified" && (
          <div>
            You must verify your email to view your account's performance
            details.
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={emailVerificationSent}
              onClick={async () => {
                await user.sendEmailVerification();
                setEmailVerificationSent(true);
              }}
              style={{ marginLeft: 14 }}
            >
              Verify Email
            </Button>
          </div>
        )}
        {accountAndProfileData?.reason !== "email_unverified" && (
          <MyAccount
            accountData={accountData.sort((a, b) => (a.date > b.date ? 1 : -1))}
          />
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/**
 * Be able to pull account data if email is verified
 * @returns {JSX.Element}
 * @constructor
 */
export default function AuthenticatedApp({ user }) {
  const classes = useStyles();
  const [
    isTaxVerificationModalShown,
    setIsTaxVerificationModalShown,
  ] = useState(false);

  const {
    data: accountAndProfileData = { accountData: [], profileData: {} },
  } = useSWR([`${HOSTNAME}/v2/account`, user], authenticatedFetcher);

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ marginBottom: 24 }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Daedalus Investment Fund
          </Typography>
          {/*{accountAndProfileData?.accountData?.length > 0 && (*/}
          {/*  <Button*/}
          {/*    color="inherit"*/}
          {/*    onClick={async () => {*/}
          {/*      setIsTaxVerificationModalShown(true);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Tax Documents*/}
          {/*  </Button>*/}
          {/*)}*/}
          <Button color="inherit" onClick={() => signOut()}>
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>

      {/*{isTaxVerificationModalShown && (*/}
      {/*  <TaxVerificationDialog*/}
      {/*    isOpen={isTaxVerificationModalShown}*/}
      {/*    handleClose={() => {*/}
      {/*      setIsTaxVerificationModalShown(false);*/}
      {/*    }}*/}
      {/*    handleSubmit={() => {}}*/}
      {/*    profileData={accountAndProfileData.profileData}*/}
      {/*    user={user}*/}
      {/*  />*/}
      {/*)}*/}

      <Container maxWidth="md">
        <PerformanceCard
          user={user}
          accountAndProfileData={accountAndProfileData}
        />

        {user?.emailVerified && (
          <>
            <FundTrrYtdCard />
            <FundTrrMonthlyYtdCard />
            <FundTrrItdCard />
          </>
        )}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Typography variant="h5">Fund Stats</Typography>
        </div>
        <iframe
          style={{
            width: "100%",
            height: 915,
          }}
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRKn96XtnEQ-b1HOVn6t4xWGZS_E8S_tasWeQajxckPKHGT8FArt3hlNmbRKwFjzTp0JMhUrj7D2CyN/pubhtml?gid=1472118357&amp;chrome=false"
        />
        <iframe
          style={{
            width: "100%",
            height: 105,
          }}
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRKn96XtnEQ-b1HOVn6t4xWGZS_E8S_tasWeQajxckPKHGT8FArt3hlNmbRKwFjzTp0JMhUrj7D2CyN/pubhtml?gid=386967672&amp;chrome=false"
        />
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Typography variant="caption">
            Data on this page is unofficial and generated on a best effort basis
            with no guarantees. Account value and graphs are net of the
            management fee and performance fee.
          </Typography>
        </div>
      </Container>
    </div>
  );
}
