import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useSWR from "swr";
import { authenticatedFetcher } from "../util";
import { useAuth } from "../authentication/firebase";
import MultiBarChart from "./MultiBarChart";
import { DateTime } from "luxon";
import { HOSTNAME } from "../constants";

const groupTrrByMonthBasedOnTwr = (dailyData = [], derivedData) => {
  const monthlyData = {};

  if (dailyData.length === 0) {
    return monthlyData;
  }

  let startCalc = dailyData[0];
  let endCalc = dailyData[0];

  let startCalcMonthStr = DateTime.fromFormat(
    startCalc.date,
    "yyyy-MM-dd"
  ).toFormat("yyyy-MM");

  for (let i = 0; i < dailyData.length; i++) {
    const data = dailyData[i];
    const monthStr = DateTime.fromFormat(data.date, "yyyy-MM-dd").toFormat(
      "yyyy-MM"
    );

    // if the month/year combo has changed
    if (startCalcMonthStr !== monthStr) {
      const startTwr = startCalc.value;
      const endTwr = endCalc.value;

      const monthTwr = (1 + endTwr / 100) / (1 + startTwr / 100) - 1;
      monthlyData[startCalcMonthStr] = {
        key: startCalcMonthStr,
        [data.name]: monthTwr,
      };

      startCalcMonthStr = monthStr;
      startCalc = endCalc;
    }
    endCalc = data;
  }

  // if the last startCalcMonthStr doesnt exist, then it means there wasn't a month change to record
  // the value for that month. Go ahead and dump that now.
  if (!monthlyData[startCalcMonthStr]) {
    const startTwr = startCalc.value;
    const endTwr = endCalc.value;

    const monthTwr = (1 + endTwr / 100) / (1 + startTwr / 100) - 1;
    monthlyData[startCalcMonthStr] = {
      key: startCalcMonthStr,
      [dailyData[0].name]: monthTwr,
    };
  }

  //if we have derived data, use it to derive the current month's bar
  if (derivedData && derivedData.length > 0) {
    // we assume this spans only one month
    const derivedTrr = derivedData[derivedData.length - 1];
    const monthYearStr = DateTime.fromFormat(
      derivedTrr.date,
      "yyyy-MM-dd"
    ).toFormat("yyyy-MM");
    const ret = (1 + derivedTrr.value / 100) / (1 + endCalc.value / 100) - 1;
    monthlyData[monthYearStr] = {
      key: monthYearStr,
      [dailyData[0].name]: ret,
    };
  }

  return monthlyData;
};

const convertToBarChartFormat = (months, fund, spx) => {
  const barDataArray = [];
  for (let i = 0; i < months.length; i++) {
    const month = months[i];
    let barData = {};

    if (spx[month]) {
      barData = { ...spx[month] };
    }

    if (fund[month]) {
      barData = { ...barData, ...fund[month] };
    }

    const monthDateTime = DateTime.fromFormat(month, "yyyy-mm");
    const nowDateTime = DateTime.local();

    if (monthDateTime.year === nowDateTime.year) {
      barDataArray.push(barData);
    }
  }

  return barDataArray;
};

// todo trr doesnt exist for daedalus fund, so monthly bar isnt generating. it should derive the last bar, so need to check this.
const FundTrrMonthlyYtdCard = () => {
  const { user } = useAuth();
  const { data: daedalusTrrYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/daedalus_trr/daily/ytd`, user],
    authenticatedFetcher
  );

  const { data: daedalusYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/daedalus/daily/ytd`, user],
    authenticatedFetcher
  );

  const { data: spxYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/$spx.x/daily/ytd`, user],
    authenticatedFetcher
  );

  const groupedFundData = groupTrrByMonthBasedOnTwr(
    daedalusTrrYtd,
    daedalusYtd
  );

  const groupedSpxData = groupTrrByMonthBasedOnTwr(spxYtd);

  const barChartData = convertToBarChartFormat(
    Object.keys(groupedSpxData),
    groupedFundData,
    groupedSpxData
  );

  return (
    <Card style={{ marginBottom: 8 }}>
      <CardHeader title="Return by Month YTD" style={{ textAlign: "center" }} />
      <CardContent>
        {!daedalusYtd && <Skeleton variant="rect" height={400} />}
        {barChartData?.length > 0 && <MultiBarChart barData={barChartData} />}
      </CardContent>
    </Card>
  );
};

export default FundTrrMonthlyYtdCard;
