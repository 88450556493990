import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useSWR from "swr";
import { authenticatedFetcher } from "../util";
import { useAuth } from "../authentication/firebase";
import MultiLineChart from "./MultiLineChart";
import { DateTime } from "luxon";
import { HOSTNAME } from "../constants";

const FundTrrItdCard = () => {
  const { user } = useAuth();
  const { data: daedalusItd = [] } = useSWR(
    [`${HOSTNAME}/v2/trr/daedalus_trr/daily/itd`, user],
    authenticatedFetcher
  );

  const { data: spxItd = [] } = useSWR(
    [`${HOSTNAME}/v2/trr/spx_trr/daily/itd`, user],
    authenticatedFetcher
  );

  const dData = daedalusItd.map((d) => {
    const tokens = d.date.split("-");
    const yearMonth = tokens[0] + "-" + tokens[1];

    return {
      ...d,
      date: yearMonth,
    };
  });

  const sData = spxItd.map((d) => {
    const tokens = d.date.split("-");
    const yearMonth = tokens[0] + "-" + tokens[1];

    return {
      ...d,
      date: yearMonth,
    };
  });

  return (
    <Card style={{ marginBottom: 8 }}>
      <CardHeader
        title="Total Fund Return ITD"
        style={{ textAlign: "center" }}
      />
      <CardContent>
        {!daedalusItd && <Skeleton variant="rect" height={400} />}
        {daedalusItd && (
          <MultiLineChart
            lineData={[
              { name: "Fund", data: dData },
              { name: "S&P 500", data: sData },
            ]}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default FundTrrItdCard;
