import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { DateTime } from "luxon";

const MultiLineChart = ({ lineData = [], tickFormatter }) => {
  const colors = ["#1769aa", "#ff9100"];
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart width={600} height={400}>
        <CartesianGrid stroke="#ccc" />
        <XAxis
          allowDuplicatedCategory={false}
          dataKey="date"
          tickFormatter={tickFormatter}
        />
        <YAxis
          tickFormatter={(value) => `${value}%`}
          width={50}
          // domain={[min, max]}
          type="number"
        />
        {lineData.map((d, index) => (
          <Line
            key={`${index}`}
            data={d.data}
            type="linear"
            dataKey="value"
            name={d.name}
            stroke={colors[index]}
            dot={false}
          />
        ))}
        <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MultiLineChart;
