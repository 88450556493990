import React, { useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import millify from "millify";
import useSWR from "swr";
import _ from "lodash";
import { authenticatedFetcher, getPerformanceByMonth } from "../util";
import { DateTime } from "luxon";
import { useAuth } from "../authentication/firebase";
import { HOSTNAME } from "../constants";

const percentPerf = (perfRatio = 0) => {
  const percentage = perfRatio * 100;
  return `${percentage.toFixed(2)}%`;
};

const MyAccountSkeleton = () => {
  return (
    <div>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  );
};

const MyAccount = ({ accountData }) => {
  const { user } = useAuth();
  const [dailyPerfRatio, setDailyPerfRatio] = useState(0);
  const [accountValue, setAccountValue] = useState(0);
  const [startOfMonthTrr, setStartOfMonthTrr] = useState(0);
  const [geoMtdTrr, setGeoMtdTrr] = useState(0);
  const [cashflow, setCashFlow] = useState(0);
  const [todaysPl, setTodaysPl] = useState(0);
  const { data: daedalusYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/daedalus/daily/ytd`, user],
    authenticatedFetcher
  );

  useEffect(() => {
    if (daedalusYtd && user.emailVerified) {
      // const currentYearMonthStr = DateTime.local().toFormat("yyyy-MM");

      let currentYearMonthStr = DateTime.fromFormat(
        daedalusYtd[daedalusYtd.length - 1].date,
        "yyyy-MM-dd"
      ).toFormat("yyyy-MM");

      // if there was no previous month ending trr, then this is the first month and the previous month should have
      // no performance
      let prevMonthEndingTrr = 0;

      for (let i = daedalusYtd.length - 1; i >= 0; i--) {
        const { date, value } = daedalusYtd[i];

        if (!date.includes(currentYearMonthStr)) {
          prevMonthEndingTrr = value;
          break;
        }
      }

      const geometricStartOfMonthTrr = prevMonthEndingTrr / 100 + 1;

      //let lastTwoDays;

      // in daily comparison, first day is usually 1 index behind current. however for a new year, there is no
      // previous index.
      // let firstDay = 1

      const lastTwoDays = daedalusYtd
        .slice(-2)
        .map((day) => ({ ...day, value: day.value / 100 + 1 }));

      // initialize perf assuming there is only one day (first day of the year).
      let geometricPerf = lastTwoDays[0].value;

      // if there is more than one day in the ytd, that means we have at least 2 trading days recorded
      if (lastTwoDays.length > 1) {
        geometricPerf = lastTwoDays[1].value / lastTwoDays[0].value;
      }

      const dailyPerfRatio = geometricPerf - 1;

      let ytdGeometricTrr = lastTwoDays[0].value;

      if (lastTwoDays.length > 1) {
        ytdGeometricTrr = lastTwoDays[1].value;
      }

      setDailyPerfRatio(dailyPerfRatio);
      setGeoMtdTrr(ytdGeometricTrr / geometricStartOfMonthTrr);
    }
  }, [daedalusYtd]);

  useEffect(() => {
    if (accountData.length > 0) {
      const startOfMonthBalance = accountData[accountData.length - 1].balance;

      // todo need to get TWR from beginning of month in order to compute account balance to this point
      // setStartOfMonthBalance(startOfMonthBalance);
      const accountVal = startOfMonthBalance * geoMtdTrr;
      setAccountValue(accountVal);

      const cf = accountData.reduce((total, val) => total + val.cashflow, 0);
      setCashFlow(cf);
    }
  }, [accountData, geoMtdTrr]);

  useEffect(() => {
    const geometricDailyPerfRatio = dailyPerfRatio + 1;
    const pl = geometricDailyPerfRatio * accountValue - accountValue;

    setTodaysPl(pl);
  }, [dailyPerfRatio, accountValue]);

  return (
    <div>
      {!daedalusYtd && <MyAccountSkeleton />}
      {daedalusYtd && (
        <Container maxWidth="md">
          <div
            style={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              <b>Daily Return</b>
              <br />
              {millify(todaysPl)}
              <br />
              {percentPerf(dailyPerfRatio)}
            </Typography>
            <Typography>
              <b>Account Value</b>
              <br />
              {accountValue !== 0 &&
                Number(accountValue).toLocaleString("en", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </Typography>
            <Typography>
              <b>Total P/L</b>
              <br />
              {Number(accountValue - cashflow).toLocaleString("en", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </div>
        </Container>
      )}
    </div>
  );
};

export default MyAccount;
