import {
  CartesianGrid,
  Legend,
  Line,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  LineChart,
} from "recharts";
import React from "react";
import { DateTime } from "luxon";
import { toPercent } from "../util";

const MultiLineChart = ({ barData = [] }) => {
  const colors = ["#1769aa", "#ff9100"];
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart width={600} height={300} data={barData}>
        <CartesianGrid stroke="#ccc" />
        <Tooltip
          labelFormatter={(value) =>
            DateTime.fromFormat(value, "yyyy-mm").toFormat("mm-yyyy")
          }
          formatter={(value) => toPercent(value, 2)}
        />
        <XAxis
          dataKey="key"
          tickFormatter={(value) => {
            const dt = DateTime.fromFormat(value, "yyyy-MM");
            return dt.toFormat("LLL");
          }}
        />
        <YAxis tickFormatter={(value) => toPercent(value, 1)} width={44} />
        <Bar
          type="monotone"
          dataKey="daedalus_trr"
          name="Fund"
          fill="#1769aa"
        />
        <Bar type="monotone" dataKey="$spx.x" name="S&P 500" fill="#ff9100" />
        <Legend />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MultiLineChart;
