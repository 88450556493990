import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useSWR from "swr";
import { authenticatedFetcher } from "../util";
import { useAuth } from "../authentication/firebase";
import MultiLineChart from "./MultiLineChart";
import { DateTime } from "luxon";
import { XAxis } from "recharts";
import { HOSTNAME } from "../constants";

const FundTrrYtdCard = () => {
  const { user } = useAuth();

  useSWR(`${HOSTNAME}/v2/quote/spx`);

  const { data: daedalusYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/daedalus/daily/ytd`, user],
    authenticatedFetcher
  );

  const { data: spxYtd } = useSWR(
    [`${HOSTNAME}/v2/trr/$spx.x/daily/ytd`, user],
    authenticatedFetcher
  );

  return (
    <Card style={{ marginBottom: 8 }}>
      <CardHeader
        title="Daily Fund Return YTD"
        style={{ textAlign: "center" }}
      />
      <CardContent>
        {(!daedalusYtd || !spxYtd) && <Skeleton variant="rect" height={400} />}
        {daedalusYtd && spxYtd && (
          <MultiLineChart
            lineData={[
              { name: "Fund", data: daedalusYtd },
              { name: "S&P 500", data: spxYtd },
            ]}
            tickFormatter={(value) => {
              if (value) {
                return DateTime.fromFormat(value, "yyyy-MM-dd").toFormat(
                  "MM-dd"
                );
              }
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default FundTrrYtdCard;
